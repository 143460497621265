import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Link, Box, Section, Icon, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, StackItem, Stack, SocialMedia, Menu } from "@quarkly/components";
import * as Components from "components";
import { MdEmail } from "react-icons/md";
import { FaSteamSquare } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"publish"} />
		<Helmet>
			<title>
				Games ported and published by Ternox 
			</title>
			<meta name={"description"} content={"I can porting and publish your GameMaker Studio game on consoles!"} />
			<meta property={"og:title"} content={"Games published by Ternox "} />
			<meta property={"og:description"} content={"I can publish your GameMaker Studio game on consoles!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/nintendo33.jpg?v=2020-10-03T21:48:55.714Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.EmbedHTML />
		<Components.Menuu />
		<Section
			padding="34px 0 64px 0"
			sm-padding="40px 0"
			font="--base"
			color="#0E1317"
			background="--color-light"
		>
			<Override slot="SectionContent" max-width="1400px" />
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				margin="0 auto 5px auto"
				text-align="center"
			>
				I am porting & publishing games to consoles
			</Text>
			<Text
				color="--darkL2"
				max-width="620px"
				margin="0 auto"
				text-align="center"
				font="--lead"
				as="p"
			>
				<Strong
					text-overflow="clip"
					hyphens="manual"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
				>
					If you have a game on Gamemaker Studio 2 and want to release it on consoles I can do it with a low publisher share.
					<br />
					My contact details are below.
					<br />
				</Strong>
			</Text>
			<Stack
				flex="0 1 auto"
				flex-wrap="wrap"
				margin-top="40px"
				margin="15px -16px -16px -16px"
				order="0"
			>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/box2.png?v=2025-03-18T10:55:46.463Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
					>
						<Link href="/ovoid" min-height="100%" min-width="100%" />
					</Box>
					<Box
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
					>
						<Link
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							border-radius="2px"
							color="#F7FBFF"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://ternoxgames.com/ScrapDivers/"
						>
							All console platforms
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/1x1.jpg?v=2024-05-31T00:44:53.091Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
					>
						<Link href="/ovoid" min-height="100%" min-width="100%" />
					</Box>
					<Box
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
					>
						<Link
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							border-radius="2px"
							color="#F7FBFF"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://ternoxgames.com/ovoid"
						>
							All console platforms
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Square-icon_512.png?v=2022-08-17T00:45:56.415Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
					>
						<Link href="https://ternoxgames.com/unichrome" min-height="100%" min-width="100%" />
					</Box>
					<Box
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
					>
						<Link
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							border-radius="2px"
							color="#F7FBFF"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://ternoxgames.com/unichrome"
						>
							All console platforms
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/icon0.png?v=2022-04-27T23:40:59.345Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
					>
						<Link href="/mymaite" min-height="100%" min-width="100%" />
					</Box>
					<Box
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
					>
						<Link
							background="--color-primary"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							border-radius="2px"
							color="#F7FBFF"
							text-decoration-line="initial"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://store.playstation.com/en-us/product/UP4892-CUSA31753_00-0495832761096949"
							target="_blank"
						>
							Playstation 4 + 5
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/maitee.jpg?v=2021-06-02T13:55:20.265Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
					>
						<Link href="/mymaite" min-height="100%" min-width="100%" />
					</Box>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							background="--color-primary"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							display="inline-block"
							href="/mymaite"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							border-radius="2px"
							hover-background="--color-darkL1"
						>
							Nintendo Switch™{"\n\n"}
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem sm-width="100%" min-width="33%" lg-width="50%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/1000_1.jpg?v=2021-05-04T22:12:14.218Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
					/>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							text-decoration-line="initial"
							border-radius="2px"
							display="inline-block"
							href="https://www.nintendo.com/games/detail/nyakamon-adventures-switch/"
							background="--color-primary"
							target="_blank"
							color="#F7FBFF"
							hover-background="--color-darkL1"
						>
							Nintendo Switch
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						hover-transform="scale(1.09)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Digital%20Box%20Art.png?v=2021-04-17T14:28:28.898Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
					/>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							margin="3px 3px 3px 3px"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://www.nintendo.com/games/detail/reactorx-switch/"
							background="--color-primary"
							target="_blank"
							padding="7px 20px 7px 20px"
							color="#F7FBFF"
							text-decoration-line="initial"
							border-radius="2px"
						>
							Nintendo Switch
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Image00001.jpg?v=2020-10-03T21:58:36.835Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
					/>
					<Box
						display="block"
						position="static"
						as="div"
						text-align="center"
						margin="auto 0px 0px 0px"
					>
						<Link
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
							text-decoration-line="initial"
							display="inline-block"
							href="https://www.nintendo.com/games/detail/void-source-switch/"
							background="--color-primary"
							target="_blank"
							padding="7px 20px 7px 20px"
							border-radius="2px"
							hover-background="--color-darkL1"
						>
							Nintendo Switch
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem sm-width="100%" min-width="33%" lg-width="50%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/NINTENDOCover1241.jpg?v=2020-10-28T19:38:02.336Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
						margin="0 0 20px 0"
					/>
					<Box
						margin="auto 0px 0px 0px"
						display="block"
						position="static"
						as="div"
						text-align="center"
					>
						<Link
							background="--color-primary"
							target="_blank"
							text-decoration-line="initial"
							border-radius="2px"
							display="inline-block"
							hover-background="--color-darkL1"
							href="https://www.nintendo.com/games/detail/dark-grim-mariupolis-switch/"
							padding="7px 20px 7px 20px"
							margin="3px 3px 3px 3px"
							color="#F7FBFF"
						>
							Nintendo Switch
						</Link>
					</Box>
					{" "}
				</StackItem>
				<StackItem lg-width="50%" sm-width="100%" min-width="33%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						transition="all 0.3s ease 0s"
						hover-transition="all 0.3s --transitionTimingFunction-easeIn 0.0s"
						hover-transform="scale(1.09)"
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/nintendo33.jpg?v=2020-10-03T21:48:55.714Z) 50% 0/cover no-repeat"
						box-shadow="3px 3px 7px 1px rgba(0, 0, 0, 0.46)"
					/>
					<Box
						overflow-y="auto"
						display="block"
						position="relative"
						as="div"
						bottom="0px"
						align-self="auto"
						margin="auto 0px 0px 0px"
						text-align="center"
						left="auto"
						right="auto"
						top="auto"
					>
						<Link
							white-space="normal"
							align-items="stretch"
							flex-wrap="wrap-reverse"
							right="auto"
							left="auto"
							href="https://www.nintendo.com/games/detail/castle-of-no-escape-switch/"
							color="#F7FBFF"
							display="inline-block"
							width="122.844px"
							bottom="1px"
							margin="0px 0px auti 0px"
							target="_blank"
							padding="7px 20px 7px 20px"
							text-decoration-line="initial"
							hover-background="--color-darkL1"
							background="--color-primary"
							border-radius="2px"
							justify-content="space-around"
							position="static"
							height="20px"
							top="1px"
						>
							Nintendo Switch
						</Link>
					</Box>
					{" "}
				</StackItem>
			</Stack>
		</Section>
		<Section
			color="--light"
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/mayclover_meadow_night_example.png?v=2020-10-03T23:33:05.039Z)"
			height="157px"
			margin="18px 0px 0px 0px"
		>
			<Override slot="SectionContent" />
			<Stack>
				<StackItem width="50%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Box
						max-width="360px"
						position="relative"
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
						margin="0x 0 0 0"
					>
						<Icon
							icon={MdEmail}
							height="48px"
							top="13px"
							size="48px"
							left="6px"
							category="md"
							bottom="auto"
							right="auto"
							width="48px"
							position="absolute"
						/>
						<Icon
							height="50px"
							size="48px"
							top="11px"
							left="auto"
							icon={FaSteamSquare}
							bottom="auto"
							position="absolute"
							category="fa"
							right="-157px"
							width="48px"
						/>
						<Text as="h4" margin="6px 0" font="--base">
							Email me
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							<Link href="mailto:me@ternoxgames.com" text-decoration="none" hover-text-decoration="underline" color="--light">
								me@ternoxgames.com
							</Link>
						</Text>
					</Box>
				</StackItem>
				<StackItem width="50%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text display="flex" font="--headline3">
						Follow me on{" \n"}
						<Link
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							color="--primary"
							href="https://quarkly.io/preview#/https://store.steampowered.com/developer/ternox"
							white-space="normal"
							overflow-wrap="normal"
							word-break="normal"
						>
							Steam
						</Link>
						{"\n\n"}
						<br />
						{"\n\n"}
					</Text>
					<Hr display="block" />
				</StackItem>
			</Stack>
			<SocialMedia
				facebook="https://www.facebook.com/taimumari"
				telegram="https://t.me/ternox"
				text-align="left"
				position="relative"
				left={0}
				icon-size="32px"
				padding="28px 0px 0px 0"
				twitter="https://twitter.com/taimumari"
				youtube="https://www.youtube.com/channel/UC1rKs1kvj_f1S7lwpHGZ9TQ/"
				display="flex"
				instagram="https://www.instagram.com/taimumari/"
				discord="https://discord.com/invite/cR49SHktjW/"
			>
				<Override slot="link">
					<div />
				</Override>
			</SocialMedia>
		</Section>
		<Section padding="32px 0" background-color="--dark" text-align="center" border-color="#eaeaea">
			<Text margin="11px 0px 11px 0px">
				Nintendo Switch is a trademark of Nintendo.{"\n"}
				<br />
				{"\n\n"}
			</Text>
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link-policy">
					Private policy
				</Override>
				<Override slot="link-oldone">
					Legacy
				</Override>
				<Override slot="link" padding="6px 12px" text-decoration="none" color="--light" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link-index">
					Main page
				</Override>
			</Menu>
			<Link
				href="mailto:hello@company.com"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
			>
				me@ternoxgames.com
			</Link>
		</Section>
	</Theme>;
});